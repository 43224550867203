import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "edit--approve-pending-events"
    }}>{`Edit & Approve Pending Events`}</h1>
    <p>{`Once an event has been submitted it needs to be reviewed and approved before it appears on the events calendar for the public to see. The Pending Approval menu link, which appears in the left sidebar, lists events that are waiting to be approved. If you are logged in as a Manager or Administrator, the page will list events that have been submitted by all members. If you are logged in as a user with Staff level access, only pending events that you have submitted will be displayed.`}</p>
    <Alert kind="warning" mdxType="Alert">Events can only be approved by users with Manager or Admin level access permissions. Users with Staff level access are able to edit their own events prior to approval from this page.</Alert>
    <h2 {...{
      "id": "approving-events"
    }}>{`Approving events`}</h2>
    <ol>
      <li parentName="ol">{`Click 'Pending Approval' on the left-hand side menu`}</li>
      <li parentName="ol">{`All submitted events pending approval are listed`}</li>
      <li parentName="ol">{`Events appear in chronological event date order`}</li>
      <li parentName="ol">{`Click the event title or the edit icon (blue square with a pencil) to view, edit and approve a listed event`}</li>
      <li parentName="ol">{`Check all event details`}</li>
      <li parentName="ol">{`Select Yes/No if the event is a Featured Event, these will appear in the carousel on the home page`}</li>
      <li parentName="ol">{`Include a Partner Logo if the event is in partnership with other organisations - click 'Choose File' and upload the image`}</li>
      <li parentName="ol">{`You can choose to 'Update This Event' or update all events in a series by selecting the drop down to update and approve all events in a series`}</li>
      <li parentName="ol">{`Click 'Publish Event', this will immediately add the event to your calendar`}</li>
      <li parentName="ol">{`Alternatively, if you wish to Delete an event, select 'Delete Event'`}</li>
    </ol>
    <h2 {...{
      "id": "contacting-event-submitters"
    }}>{`Contacting Event Submitters`}</h2>
    <p>{`When a Manager or Administrator is reviewing an event that's pending approval, it's possible for them to contact the staff member who submitted the event to ask them to make changes. This is helpful when important details are missing from the event's description that only the submitter can provide. The submitter will receive an email that contains a custom message relating to the event, along with a link that allows them to directly make revisions.`}</p>
    <ol>
      <li parentName="ol">{`View an event that is 'Pending Approval' after following the instructions above`}</li>
      <li parentName="ol">{`Click 'Contact Submitter' in the right sidebar`}</li>
      <li parentName="ol">{`Type your custom message to the event submitter, include any important information, and review their email address`}</li>
      <li parentName="ol">{`Click the Send button`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/gioVa2EFML8" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      